import React, { useState, useEffect, useRef } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../Assets/Style/Carousel.scss';

const banners = [
    { src: 'https://cdn.lonestarmga.com/img/Banners/WIN_Web-banner_1693x350_Heifer-International-v3.png', alt: 'Charity Link Banner', link: 'https://cdn.lonestarmga.com/pdf/WIN Charitable Program Heifer International Eblast.pdf' },
    { src: 'https://cdn.lonestarmga.com/img/Banners/Lonestar_Web-Banner_License-Producer-v2.png', alt: 'Producer Banner', link: '' },
];

export default function Carousel() {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [isAnimating, setIsAnimating] = useState(false); // Controls whether a slide is currently in progress
    const [isTransitioning, setIsTransitioning] = useState(true); // Controls whether the CSS transition effect is enabled
    const intervalRef = useRef(null);
    const carouselRef = useRef(null);

    // Duplicate the first and last slides for seamless looping
    const bannersWithClones = [
        banners[banners.length - 1], // Clone last banner at the beginning
        ...banners,
        banners[0], // Clone first banner at the end
    ];

    const startAutoSlide = () => {
        intervalRef.current = setInterval(() => {
            nextSlide();
        }, 7000);
    };

    const stopAutoSlide = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    const resetAutoSlide = () => {
        stopAutoSlide();
        startAutoSlide();
    };

    const nextSlide = () => {
        if (!isAnimating) {
            setIsAnimating(true);
            setIsTransitioning(true);
            setCurrentIndex((prevIndex) => prevIndex + 1);
            resetAutoSlide();
        }
    };

    const prevSlide = () => {
        if (!isAnimating) {
            setIsAnimating(true);
            setIsTransitioning(true); 
            setCurrentIndex((prevIndex) => prevIndex - 1);
            resetAutoSlide();
        }
    };

    useEffect(() => {
        const handleTransitionEnd = () => {
            setIsAnimating(false);

            if (currentIndex === 0) {
                setIsTransitioning(false);
                setCurrentIndex(banners.length);
            } else if (currentIndex === banners.length + 1) {
                setIsTransitioning(false);
                setCurrentIndex(1);
            }
        };

        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('transitionend', handleTransitionEnd);
        }

        return () => {
            if (carousel) {
                carousel.removeEventListener('transitionend', handleTransitionEnd);
            }
        };
    }, [currentIndex, banners.length]);

    useEffect(() => {
        startAutoSlide(); 

        return () => stopAutoSlide();
    }, []);

    return (
        <div className="carousel-container">
            <div
                ref={carouselRef}
                className="carousel"
                style={{
                    transform: `translateX(${-currentIndex * 100}%)`,
                    transition: isTransitioning ? 'transform 0.5s ease-in-out' : 'none', 
                }}
            >
                {bannersWithClones.map((banner, index) => (
                    <a
                        key={index}
                        href={banner.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="carousel-slide"
                    >
                        <img
                            src={banner.src}
                            alt={banner.alt}
                        />
                    </a>
                ))}
            </div>

            {/* Previous Button */}
            <button className="carousel-button prev-button" onClick={prevSlide}>
                <i className="fas fa-chevron-left"></i>
            </button>

            {/* Next Button */}
            <button className="carousel-button next-button" onClick={nextSlide}>
                <i className="fas fa-chevron-right"></i>
            </button>
        </div>
    );
}
